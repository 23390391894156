@import "../../assets/css/color";
.page-title {
    h2 {
        font-weight: 400;
        font-size: 64px;
        text-align: center;
        letter-spacing: -0.02em;
        color: $color-blue-dark;
        margin-bottom: 32px;
    }
    p {
        font-weight: 500;
        font-size: 18px;
        color: $color-blue-dark;
        opacity: 0.5;
        text-align: center;
        margin-bottom: 0;
    }
}
.makingmoney {
    padding: 80px 0;
    .making-box {
        text-align: center;
        margin-top: 72px;
        img {
            max-width: 100%;
            border-radius: 25px;
            cursor: pointer;
        }
        h3 {
            display: block;
            font-size: 14px;
            margin-bottom: 16px;
            text-align: center;
            letter-spacing: 0.2em;
            color: $color-red-dark;
            font-weight: 700;
            margin-top: 32px;
        }
        h2 {
            font-weight: 700;
            font-size: 24px;
            text-align: center;
            color: $color-blue-dark;
            margin-bottom: 16px;
            cursor: pointer;
            line-height: 1.6;
        }
        p {
            font-weight: 500;
            font-size: 18px;
            color: $color-blue-dark;
            opacity: 0.5;
            margin-bottom: 32px;
            line-height: 32px;
        }
        & .date-and-name {
            margin-bottom: 38px;
            span {
                display: inline-block;
                margin: 0;
                &.date {
                    font-weight: 500;
                    font-size: 14px;
                    color: $color-blue-dark;
                    opacity: 1;
                }
                &.name {
                    color: $color-blue-dark;
                    opacity: 0.5;
                    font-weight: 500;
                    font-size: 14px;
                }
                &.red-dot {
                    margin-left: 24px;
                    margin-right: 24px;
                    background-color: $color-red-dark;
                    border-radius: 50%;
                    width: 4px;
                    height: 4px;
                }
            }
        }
    }
}
@media (max-width: 1525px) {
    .makingmoney {
        .making-box {
            h2 {
                font-size: 20px;
            }
            p {
                font-size: 16px;
            }
        }
    }
}
@media all and (max-width: 1024px) {
    .makingmoney {
        padding: 60px 0;
        .making-box {
            text-align: left;
        }
    }
    .page-title {
        h2 {
            font-size: 40px;
            margin-bottom: 20px;
        }
        p {
            font-size: 16px;
        }
    }
    .makingmoney {
        .making-box {
            margin-top: 40px;
            h3 {
                font-size: 12px;
            }
            h2 {
                font-size: 18px;
            }
            p {
                font-size: 15px;
                line-height: 1.5;
                min-height: 180px;
            }
            .date-and-name {
                span {
                    &.name {
                        font-size: 12px;
                    }
                    &.date {
                        font-size: 12px;
                    }
                    &.red-dot {
                        margin-left: 17px;
                        margin-right: 24px;
                    }
                }
            }
        }
    }
}
@media all and (max-width: 767px) {
    .page-title {
        h2 {
            text-align: left;
            font-weight: 300;
            font-size: 32px;
            line-height: 39px;
            letter-spacing: -0.02em;
            strong {
                font-weight: 700;
            }
        }
        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: left;
        }
    }
    .makingmoney {
        padding-top: 140px;
        padding-bottom: 140px;
        .col-md-6 {
            margin-bottom: 25px;
            &:last-child {
                margin: 0;
            }
        }
        .making-box {
            margin-top: 25px;
            text-align: left;
            h3 {
                font-size: 12px;
                margin-top: 40px;
                margin-bottom: 24px;
                text-align: left;
            }
            h2 {
                font-size: 18px;
                text-align: left;
            }
            p {
                text-align: left;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 32px;
            }
            .date-and-name {
                text-align: left;
                margin-bottom: 32px;
                span {
                    &.name {
                        font-size: 14px;
                    }
                    &.date {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
