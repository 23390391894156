$screen-sm-max: 599px;
$screen-md-max: 899px;
$screen-lg-max: 1199px;
$screen-lg-medium: 1366px;
$screen-xl-max: 1535px;
$screen-sm-bootstrap: 767px;

@mixin sm-down {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}
@mixin sm-down-bootstrap {
    @media (max-width: #{$screen-sm-bootstrap}) {
        @content;
    }
}
@mixin md-down {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}
@mixin lg-down {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}
@mixin lg-medium-down {
    @media (max-width: #{$screen-lg-medium}) {
        @content;
    }
}
@mixin lg-deskop {
    @media (min-width: 1200px) and (max-width: 1365px) {
        @content;
    }
}

@mixin xl-down {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
}
