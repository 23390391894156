// .customMarginLeft{
//     margin-left: -22px;
// }
// .customMarginRight{
//     margin-right: -19px;
// }
// #default-carousel{
//     margin-top: -3em;
//     .relative {
//         .duration-700{
//             .carousalImg{
//                 height: 100%;
//             }
//         }
//       }
// }
// #default-carousel {
//     .flex {
//       transition: transform 0.7s ease-in-out;
//     }
//   }
// @media (max-width: 1074px) {
//     #default-carousel .relative .duration-700 .carousalImg {
//         height: 84%;
//     }
// }
// @media (max-width: 989px) {
//     #default-carousel .relative .duration-700 .carousalImg {
//         height: 68%;
//     }
//     .white_btn{
//         width: 2em;
//         height: 2em;
//         img{
//             width: 10px;
//         }
//     }
//     .customMarginLeft{
//         margin-left: -22px;
//     }
//     .customMarginRight {
//         margin-right: -14px;
//     }
// }
// @media (max-width: 765px) {
//     #default-carousel .relative .duration-700 .carousalImg {
//         height: 93%;
//     }
// }
// @media (max-width: 391px) {
//     .insurance_homepage .insurance_homepage_inner .insurance_items {
//         margin-top: -62px;
//     }
//     #default-carousel .relative .duration-700 .carousalImg {
//         height: 79%;
//     }
//     #default-carousel {
//         padding: 0px 10px;
//     }
//     .customMarginRight {
//         margin-right: -3px;
//     }
//     .customMarginLeft {
//         margin-left: -3px;
//     }
//     .white_btn {
//         width: 1.5em;
//         height: 1.5em;
//     }
//     .white_btn img {
//         width: 8px;
//     }
// }
// @media (max-width: 318px) {
//     #default-carousel {
//         margin-top: -3.5em !important;
//     }
//     #default-carousel .relative .duration-700 .carousalImg {
//         height: 69%;
//     }
// }

.slick-prev:before, .slick-next:before {
    color: rgb(255 255 255);
    font-size: 41px;
}
.slick-prev, .slick-next {
    width: 38px;
    height: 38px;
    z-index: 1;
    background: rgba(95, 99, 104, 1);
    border-radius: 50%;
}
.slick-prev {
    left: -11px;
}
.slick-next {
    right: -13px;
}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    background: rgba(95, 99, 104, 1);
}
