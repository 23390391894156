@import "../../assets/css/color.scss";
@import "../../assets/css/MuiMediaQueries.scss";
body {
     overflow-x: hidden;
}
.homeBanner {
     padding-bottom: 200px;
     .bannerText {
          text-align: left;
          padding-top: 40px;
          // background-image: url(../../assets/images/no-joining.webp);
          background-repeat: no-repeat;
          background-position: calc(100% - 130px) 30px;
          h1 {
               font-weight: 700;
               font-size: 48px;
               line-height: 70px;
               letter-spacing: -0.02em;
               mix-blend-mode: normal;
               color: $color-blue-dark;
               span {
                    font-weight: 500;
                    font-size: 45px;
               }
          }
          ul {
               margin-top: 40px;
               margin-bottom: 65px;
               padding-left: 60px;
               max-width: 460px;
               li {
                    text-align: left;
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 32px;
                    color: $para-color;
                    margin-bottom: 25px;
                    list-style: none;
                    position: relative;
                    &::before {
                         content: "";
                         width: 36px;
                         height: 42px;
                         position: absolute;
                         background-repeat: no-repeat;
                         left: -54px;
                         top: 3px;
                         background-size: 35px;
                    }
                    &:first-child {
                         &::before {
                              background-image: url("../../assets/images/list-icon-01.webp");
                              
                         }
                    }
                    &:nth-child(2) {
                         &::before {
                              background-image: url("../../assets/images/list-icon-07.webp");
                              width: 40px;
                              height: 46px;
                         }
                    }
                    &:nth-child(3) {
                         &::before {
                              background-image: url("../../assets/images/list-icon-02.webp");    
                              background-size: 30px;

                         }
                    }
                    &:nth-child(4) {
                         &::before {
                              background-image: url("../../assets/images/list-icon-06.webp");
                              width: 46px;
                              background-size: 43px;
                         }
                    }
                    &:nth-child(5) {
                         &::before {
                              background-image: url("../../assets/images/list-icon-05.webp");
                              background-size: 27px;
                              width: 27px;
                              height: 38px;
                              top: -2px;
                         }
                    }
               }
               a {
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 700;
                    padding: 24px 56px 24px 56px;
                    text-decoration: none;
                    border-radius: 35px;
                    display: inline-block;
                    margin-top: 50px;
               }
          }
     }
     .cape-grid {
          max-width: 700px;
          flex:0 0 700px;
          position: absolute;
          right: 0;
          @include sm-down-bootstrap {
               position: relative;
               max-width: 100%;
               min-width: 100%;
          }
     }
     img {
          position: absolute;
          right: -105px;
          z-index: -1;
          max-width: 700px;
          top: 0;
          @include xl-down {
               right: 0;
          }
     }
     .container {
          position: relative;
          padding: 0 140px 0 80px;
     }
}
.footerNone a:hover {
     opacity: 0.8;
}
.getStashed {
     background-color: #d42d2d;
     margin: 0 20px;
     background-image: url(../../assets/images/getCsdTopBG.webp);
     background-repeat: no-repeat;
     background-position: center top;
     background-size: cover;
     border-radius: 40px;
     position: relative;
     z-index: 2;
}
.getStashed h2 {
     font-family: "Montserrat";
     font-style: normal;
     font-weight: 400;
     font-size: 64px;
     line-height: 78px; /* identical to box height */
     text-align: center;
     letter-spacing: -0.02em;
     color: #ffffff;
     @include xl-down {
          font-size: 50px;
          line-height: 75px;
     }
     br {
          display: none;
     }
}
.getStashed h2 span {
     font-weight: 700;
}
.getStashed h3 {
     font-weight: 500;
     font-size: 39px;
     line-height: 48px;
     text-align: center;
     letter-spacing: -0.02em;
     color:$color-white;
     margin-bottom: 0;
     @include md-down {
               font-size: 24px;
     }
}
.SimplSteps {
     padding-top: 140px;
}
.steps-3 {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     -ms-flex-pack: distribute;
     justify-content: space-around;
     padding: 0 50px;
     margin-top: 80px;
     padding-bottom: 50px;
}
.SimplSteps .nmbr {
     font-weight: 700;
     font-size: 64px;
     line-height: 78px;
     letter-spacing: -0.02em;
     color: #ffffff;
}
.SimplSteps p {
     font-style: normal;
     font-weight: 700;
     font-size: 24px;
     line-height: 32px;
     text-align: center;
     color: #ffffff;
}
.SimplSteps .brdr {
     font-size: 28px;
     color: #ffffff;
     font-weight: 600;
     @include md-down {
        font-size: 22px;  
     }
}
.starRtng {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-align: baseline;
     -ms-flex-align: baseline;
     align-items: baseline;
     display: block;
}
.starRtng .rtngTxt {
     font-weight: 700;
     font-size: 84px;
     line-height: 102px;
     letter-spacing: -0.02em;
     color: #ffffff;
     line-height: normal;
     text-align: left;
     margin-left: 30px;
}
.starRtng .rtngTxt span {
     font-weight: 700;
     font-size: 18px;
     line-height: 22px;
     display: block;
     letter-spacing: 0;
}
.starRtng img,
.starRtng .rtngTxt {
     display: inline-block;
     vertical-align: middle;
}
.blueBg {
     background-repeat: no-repeat;
     background-size: cover;
     background-position: center center;
     margin-top: -300px;
     padding-bottom: 140px;
}
.blueRatingBox {
     padding-top: 500px;
}
.blueRatingBox {
     .MuiButton-root {
          padding: 15px 50px 15px 40px;
          border-radius: 60px;
          margin-top: 40px;
          font-weight: 700;
          font-size: 16px;
          line-height: 32px;
          color: $color-blue-dark;
          background-color: $color-white;
          &:hover {
               opacity: 0.8;
               background-color: $color-white !important;
          }
     }
     button {
          clear: both;
          display: inline-block;
          padding: 15px 50px 15px 40px;
          border-radius: 60px;
          margin-top: 40px;
          font-weight: 700;
          font-size: 16px;
          line-height: 32px;
          color: $color-blue-dark;
          background-color: $color-white;
     }
}
.blueRatingBox button:hover {
     opacity: 0.8;
}
.blueRatingBox button img {
     margin-right: 5px;
}
.blueRatingBox button img,
.blueRatingBox button span {
     display: inline-block;
     vertical-align: middle;
}
.TestimonialBox {
     padding-top: 140px;
     .testiMonials {
          background-image: url(../../../src/assets/images/qoute.webp);
          background-repeat: no-repeat;
          padding: 80px 12% 0;
          background-position: 0 35px;
     }
}
.testiMonials p {
     font-weight: 500;
     font-size: 32px;
     line-height: 39px;
     color: #ffffff;
     margin-bottom: 57px;
     margin-left: 24px;
}
.testiMonials p.author {
     font-size: 24px;
     font-weight: 700;
     margin-bottom: 0;
}
.testiMonials p.author span {
     font-weight: 500;
     font-size: 16px;
     line-height: 20px;
     color: #ffffff;
     opacity: 0.6;
     display: block;
}
.testiMonials .col-md-4 img {
     width: 100%;
     border-radius: 55px;
}
.text-left {
     text-align: left;
}
.subscribeBox {
     background-image: url(../../assets/images/red-Subscribe.webp);
     background-size: cover;
     border-radius: 24px;
     background-repeat: no-repeat;
     margin: 0 20px;
     padding: 80px 150px;
     position: relative;
     z-index: 2;
     background-position: center;
}
.subscribeBox h2 {
     font-weight: 700;
     font-size: 45px;
     line-height: 64px;
     text-align: center;
     letter-spacing: -0.02em;
     color: #ffffff;
     margin-bottom: 45px;
}
button.btn {
     color: #ffffff;
     font-size: 18px;
     font-weight: 700;
     padding: 24px 56px 24px 56px;
     text-decoration: none;
     border-radius: 45px;
     display: inline-block;
     background: #ee0025;
}
button.btn:hover {
     color: #ffffff;
     background-color: #c3001e;
}
div.inputBox {
     background: #fff;
     display: inline-block;
     padding: 5px;
     border-radius: 60px;
     padding-left: 50px;
}
div.inputBox input {
     border: none;
     min-width: 350px;
     font-size: 18px;
     line-height: 22px;
     text-align: left;
     color: rgba(0, 0, 0, 0.3);
     display: inline-block;
     vertical-align: middle;
}
div.inputBox input:focus,
div.inputBox input:visited,
div.inputBox input:focus-visible {
     border: none;
     outline: none;
}
div.inputBox input:-ms-input-placeholder,
div.inputBox input::-webkit-input-placeholder,
div.inputBox input::-moz-placeholder {
     font-size: 18px;
     line-height: 22px;
     text-align: center;
     color: rgba(0, 0, 0, 0.3);
}
img {
     max-width: 100%;
}
.homeBanner {
     &.show-apply-button {
          .apply-button-container {
               -webkit-transform: translateY(0px);
               -ms-transform: translateY(0px);
               transform: translateY(0px);
          }
     }
     .apply-button-container {
          position: fixed;
          left: 37px;
          bottom: 30px;
          text-align: left;
          z-index: 9999;
          -webkit-transition: 0.5s;
          -o-transition: 0.5s;
          transition: 0.5s;
          .MuiButton-root {
               border-radius: 15px;
               font-weight: 700;
               font-size: 28px;
               height: 66px;
               -webkit-box-shadow: 0px 0px 77px 10px #ee002566;
               box-shadow: 0px 0px 77px 10px #ee002566;
          }
     }
}
div.inputBox input::-moz-placeholder,
img {
     max-width: 100%;
}
.rupeeText {
     font-family: Arial, Helvetica, sans-serif;
     font-size: 98%;
     font-weight: 300 !important;
}
.chatIcon {
     position: fixed;
     right: 4px;
     bottom: -6px;
     z-index: 9999;
     cursor: pointer;
}
.MuiTooltip-tooltipPlacementTop {
     margin-bottom: 0 !important;
     margin-bottom: -20px !important;
     margin-right: 30px;
     h4 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: $color-blue-dark;
          font-family: "Montserrat", sans-serif !important;
     }
     p {
          font-family: "Montserrat", sans-serif !important;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: $para-color;
          margin-bottom: 0;
     }
}
.OurQuality {
     margin-bottom: 100px;
     img {
          margin-right: 35px;
     }
     h3 {
          font-weight: 700;
          font-size: 30px;
          line-height: 37px;
          color: $color-blue-dark;
     }
}
.CreditLine {
     padding: 80px 0;
     h2 {
          font-weight: 700;
          font-size: 64px;
          line-height: 85px;
          text-align: center;
          letter-spacing: -0.02em;
          color: $color-blue-dark;
          span {
               font-weight: 500;
               display: block;
               font-size: 50px;
          }
     }
     h3 {
          font-weight: 700;
          font-size: 39px;
          line-height: 48px;
          text-align: center;
          color: $color-blue-dark;
          margin-top: 40px;
          span {
               display: none;
          }
     }
     img {
          &.HomeAnimation {
                width: 450px;
                margin-top: 23%;
                @include xl-down {
                    margin-top: 140px;
                }
                @include lg-down {
                    margin-top: 125px;
                }
                @include md-down {
                    margin-top: 100px;
                }
               @include sm-down {
                    margin-top: 25px;
               }
          }
          &.RoundedAnimation {
               width: 355px;
               margin-top: 8%;
               @include xl-down {
                    margin-top: 45px;
                }
          }
          &.TravelerAnimation {
                width: 450px;
                margin-top: 12%;
                @include xl-down {
                    margin-top: 70px;
                }
                @include lg-down {
                    margin-top: 70px;
                }
                @include md-down {
                    margin-top: 50px;
                }
                @include sm-down {
                    margin-top: 25px;
               }
          }
     }
     .col-md-6 {
          text-align: center;
     }
}
@media (min-width: 768px) {
     .mobileDownloadBtn,
     .mobileAuthor,
     .mobileDownloadBtn {
          display: none !important;
     }
     .blueBg {
          background-image: url(../../assets/images/blueBg.webp);
     }
}
@media (max-width: 1525px) {
     .homeBanner {
          padding-bottom: 200px;
          .apply-button-container {
               .MuiButton-root {
                    font-size: 18px;
                    height: 50px;
               }
          }
          .container {
               padding: 0 160px 0 80px;
          }
     }
}
@media (max-width: 1366px) {
     .homeBanner {
          padding-bottom: 100px;
          .container {
               padding: 0 110px 0 80px;
          }
          img {
               max-width: 550px;
          }
          .bannerText {
               h1 {
                    font-size: 40px;
                    line-height: 55px;
                    span {
                         font-size: 42px;
                    }
               }
               ul {
                    li {
                         font-size: 22px;
                         line-height: 33px;
                    }
               }
          }
     }
     .OurQuality {
          margin-bottom: 50px;
          img {
               margin-right: 24px;
               width: 72px;
          }
          h3 {
               font-size: 25px;
          }
     }
     .CreditLine {
          h3 {
               font-size: 35px;
               line-height: 44px;
          }
     }
}
@media (max-width: 1250px) {
     .subscribeBox h2 {
          font-size: 42px;
     }
}
@media (max-width: 1250px) {
     .subscribeBox h2 {
          font-size: 42px;
     }
}
@media (max-width: 1199px) {
     .homeBanner img {
          max-width: 430px;
     }
     .homeBanner .bannerText h1 {
          font-size: 46px;
          line-height: 1.3;
     }
     .subscribeBox h2 {
          font-size: 34px;
          line-height: 49px;
     }
     .homeBanner {
          padding-bottom: 200px;
          .container {
               padding: 0 84px 0 80px;
          }
     }
     .page-title h1 {
          font-size: 54px;
     }
     .makingmoney .making-box h2 {
          font-size: 18px;
     }
     .makingmoney .making-box p {
          font-size: 16px;
          line-height: 25px;
     }
     .homeBanner .bannerText {
          background-position: calc(100% - 80px) 30px;
          padding-top: 20px;
     }
     .homeBanner .bannerText ul {
          padding-top: 20px;
          margin-top: 20px;
     }
     .homeBanner .bannerText a {
          margin-top: 20px;
     }
     .homeBanner {
          padding-bottom: 70px;
     }
     .blueRatingBox {
          padding-top: 420px;
     }
     .testiMonials p {
          font-size: 25px;
     }
     .testiMonials p.author {
          font-size: 20px;
     }
     .OurQuality {
          h3 {
               font-size: 20px;
               margin-bottom: 0;
          }
          img {
               margin-right: 24px;
               width: 50px;
          }
     }
     .CreditLine {
          padding: 0 0 50px;
          h2 {
               font-size: 48px;
               line-height: 60px;
               span {
                    font-size: 35px;
               }
          }
          h3 {
               font-size: 24px;
               line-height: 32px;
               margin-top: 25px;
          }
          img {
               width: 300px;
          }
     }
}
@media (max-width: 1023px) {
     .homeBanner {
          .container {
               padding: 0 20px;
          }
     }
     .navigation ul li a {
          padding: 0 7px;
          font-size: 12px;
     }
     .headerBtn button {
          padding: 15px 20px;
          font-size: 14px;
     }
     .qrDropDown {
          top: 50px;
          padding: 15px;
     }
     .qrDropDown p {
          font-size: 12px;
          padding: 10px 0;
     }
     .homeBanner img {
          max-width: 290px;
     }
     .homeBanner .bannerText h1 {
          line-height: 47px;
          font-size: 30px;
          padding-right: 50px;
     }
     .homeBanner .bannerText h1 span {
          font-size: 24px;
          line-height: normal;
     }
     .homeBanner .bannerText ul li {
          line-height: 25px;
          padding-left: 10px;
          margin-bottom: 30px;
     }
     .homeBanner .bannerText ul {
          padding-top: 0px;
          padding-left: 40px;
          margin-bottom: 40px;
          li {
               &::before {
                    left: -40px;
                    top: -2px;
               }
               &:nth-child(3) {
                    &::before {
                         
                    }
               }
               &:nth-child(5) {
                    &::before {
                         top: -5px;
                    }
               }
          }
     }
     .homeBanner .bannerText a {
          padding: 20px 45px;
          margin-top: 25px;
     }
     .homeBanner {
          padding-bottom: 40px;
     }
     .getStashed h2 {
          font-size: 34px;
          line-height: 50px;
     }
     .steps-3 {
          padding: 0;
     }
     .SimplSteps .nmbr {
          font-size: 45px;
     }
     .SimplSteps p {
          font-size: 14px;
          line-height: 20px;
     }
     .SimplSteps {
          padding-top: 80px;
     }
     .steps-3 {
          margin-top: 40px;
     }
     .TestimonialBox {
          padding-top: 60px;
     }
     .blueBg {
          margin-top: -190px;
          padding-bottom: 90px;
     }
     .blueRatingBox {
          padding-top: 250px;
          padding-bottom: 80px;
     }
     .testiMonials .col-md-4 img {
          max-width: 100%;
     }
     .TestimonialBox .testiMonials {
          padding: 50px 5% 0;
     }
     .testiMonials p {
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 30px;
     }
     .testiMonials p.author {
          font-size: 16px;
     }
     .testiMonials p.author span {
          font-size: 14px;
          margin-top: 7px px;
     }
     .subscribeBox {
          padding: 50px 20px;
     }
     body .css-r6p1as-MuiButtonBase-root-MuiButton-root {
          font-size: 15px;
          padding: 14px 35px;
     }
     .page-title h1 {
          font-size: 40px;
     }
     body .css-r6p1as-MuiButtonBase-root-MuiButton-root {
          text-transform: capitalize;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          padding: 17px 59px;
     }
     .CreditLine {
          padding: 50px 0 50px;
          h2 {
               font-size: 24px;
               line-height: 37px;
               span {
                    font-size: 18px;
               }
          }
          h3 {
               font-size: 20px;
               line-height: 25px;
               margin-top: 15px;
          }
          img {
               &.RoundedAnimation {
                    width: 270px;
                    margin-top: 25px;
               }
          }
     }
     .OurQuality {
          img {
               margin-right: 10px;
               width: 18px;
          }
          h3 {
               font-size: 10px;
               line-height: normal;
          }
     }
}
@media (max-width: 767px) {
     .MuiTooltip-tooltipPlacementTop {
          margin-bottom: -5px !important;
          margin-right: 20px !important;
          h4 {
               font-size: 16px;
          }
          p {
               font-size: 15px;
               line-height: 25px;
          }
     }
     .homeBanner {
          padding-bottom: 50px;
          margin-top: 15px;
     }
          .homeBanner .bannerText h1 span {
               font-size: 20px;
               line-height: normal;
               margin-bottom: 5px;
               display: block;
               margin-top: 10px;
          }
     .homeBanner img {
          position: static;
          max-width: 100%;
     }
     .OurQuality {
          margin-bottom: 13px;
     }
     .SimplSteps {
          padding-top: 29px;
          .nmbr {
               font-size: 32px;
          }
          p {
               font-size: 14px;
               line-height: 17px;
               font-weight: 500;
          }
     }
     .getStashed {
          background-image: url(../../assets/images/step-background.webp);
          background-repeat: no-repeat;
          background-position: center;
          margin-top: 25px;
          h2 {
               font-size: 18px;
               line-height: 32px;
               br {
                    display: block;
               }
          }
          .steps-3 {
               display: block;
               margin-top: 10px;
          }
     }
     .getStashed .SimplSteps .brdr {
          display: inline-block;
          font-size: 0;
          height: 40px;
          border-right: #fff 2px dashed;
     }
     .starRtng img {
          max-width: 28px;
     }
     .starRtng .rtngTxt {
          font-size: 36px;
          line-height: 44px;
     }
     .blueRatingBox .col-md-6 button[type="submit"] {
          display: none;
     }
     .blueRatingBox .mobileDownloadBtn button {
          font-size: 14px;
          padding: 9px 66px;
     }
     .testiMonials .col-md-8 p.author {
          display: none;
     }
     .testiMonials .col-md-4 img {
          max-width: 93px;
          border-radius: 9px;
     }
     .testiMonials p.mobileAuthor {
          padding: 20px 0 0;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 24px;
     }
     .testiMonials p {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
          margin-left: 0;
          margin-bottom: 0;
     }
     .TestimonialBox {
          padding-top: 0;
          .testiMonials {
               background-size: 86px;
               background-position-x: 18%;
               padding-top: 80px;
          }
     }
     .subscribeBox h2 {
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          letter-spacing: -0.02em;
          color: #ffffff;
          mix-blend-mode: normal;
     }
     button.btn {
          font-size: 14px;
          line-height: 17px;
          padding: 16px 28px;
     }
     .blueBg {
          padding-bottom: 90px;
     }
     div.inputBox input {
          min-width: 0;
          max-width: calc(100% - 135px);
     }
     div.inputBox {
          padding-left: 20px;
     }
     .blueBg {
          background-image: url(../../assets/images/blueBgMobile.webp);
     }
     .blueRatingBox {
          padding-top: 430px;
     }
     .blueBg {
          margin-top: -350px;
     }
     .starRtng .rtngTxt {
          margin-left: 10px;
     }
     .homeBanner .bannerText h1 {
          font-size: 25px;
          line-height: 35px;
          padding-right: 0;
          br {
               display: block;
          }
     }
     .homeBanner .bannerText {
          ul {
               padding-left: 40px;
               margin-top: 25px;
               margin-bottom: 30px;
               li {
                    font-size: 14px;
                    line-height: 23px;
                    color: rgba(0, 0, 102, 0.5);
                    opacity: 1;
                    margin-bottom: 15px;
                    padding-left: 0;
                    &:last-child {
                         margin-bottom: 0;
                    }
                    &::before {
                         border-radius: 50%;
                         width: 25px;
                         height: 30px;
                         background-size: 23px;
                         background-position: center;
                         left: -38px;
                         top: -2px;
                    }
                    &:first-child {
                         &::before {
                              background-size: 22px;
                         }
                    }
                    &:nth-child(2) {
                         &::before{
                              background-size: 25px;
                              width: 28px;
                              height: 30px;
                              top: -1px;
                         }

                    }
                    &:nth-child(3) {
                         &::before {
                              background-size: 21px;
                              width: 30px;
                              height: 30px;
                              left: -42px;
                              top: 1px;
                         }
                    }
                    &:nth-child(4) {
                         &::before {
                              background-size: 27px;
                              height: 33px;
                              left: -48px;
                         }
                    }
                    &:nth-child(5) {
                         &::before {
                              background-size: 22px;
                              width: 27px;
                              height: 29px;
                              top: -3px !important;
                         }
                    }
               }
          }
     }
     .homeBanner .bannerText a {
          font-size: 14px;
          line-height: 17px;
          padding: 19px 31px;
     }
     .homeBanner img {
          margin-top: 20px;
          left: 0px !important;
          margin-left: 0;
     }
     body .page-title h1,
     body .page-title p,
     body .makingmoney .making-box h3,
     body .makingmoney .making-box h2,
     body .makingmoney .making-box p,
     body .makingmoney .making-box .date-and-name {
          text-align: left;
     }
     body .page-title h1 {
          font-size: 32px;
          line-height: 39px;
     }
     body .makingmoney {
          padding: 50px 0;
     }
     body .page-title p {
          font-size: 14px;
          line-height: 24px;
     }
     .makingmoney .making-box {
          margin-top: 45px;
     }
     body .makingmoney .making-box h3 {
          font-size: 12px;
          line-height: 15p;
     }
     body .makingmoney .making-box h2 {
          font-size: 22px;
     }
     body .makingmoney .making-box p {
          font-size: 16px;
          line-height: 24px;
          min-height: auto;
     }
     .homeBanner .bannerText {
          background-position: calc(100% - 20px) 30px;
          background-size: 110px;
          padding-top: 0;
          margin-bottom: 20px;
     }
     .testiMonials .col-md-4 {
          text-align: center;
     }
     .App {
          overflow: hidden;
     }
     .homeBanner {
          &.show-apply-button {
               .apply-button-container {
                    padding: 8px;
               }
          }
          .apply-button-container {
               left: 25px;
               bottom: 19px;
               .MuiButton-root {
                    font-size: 12px;
                    border-radius: 12px;
                    height: 40px;
                    padding: 0 15px;
               }
          }
          .chatIcon {
               bottom: 0;
               img {
                    width: 80px;
               }
          }
     }
     .chatIcon {
          right: -10px;
          bottom: -10px;
     }
}
@media (max-width: 450px) {
     .subscribeBox {
          padding: 30px 0px;
     }
     .subscribeBox h2 {
          margin-bottom: 20px;
     }
     div.inputBox input {
          font-size: 14px;
     }
     button.btn {
          font-size: 12px;
          line-height: 17px;
          padding: 10px 17px;
     }
     div.inputBox {
          padding-left: 5px;
     }
     div.inputBox input {
          max-width: calc(100% - 100px);
     }
     .stepBottomImg img {
          max-width: 150px;
     }
     .starRtng .rtngTxt {
          font-size: 26px;
          line-height: 30px;
     }
     .starRtng .rtngTxt span {
          font-size: 12px;
     }
     .makingmoney .making-box {
          margin-top: 32px;
     }
}