.insurance_homepage{
    display: flex;
    flex-direction: column;
    align-items: center;
    .insurance_homepage_inner{
        width: 90%;
        z-index: 1;
        .redLineDiv{
            width: 100%;
            height: 9px;
            border-radius: 10px;
            background-color: rgba(255, 0, 12, 1);
            margin-top: 28px;
        }
        .carousalDiv{
            margin-top: -40px;
        }
        .insurance_items{
            display: flex;
            flex-wrap: wrap;
            gap: 3%;
            justify-content: center;
            margin-top: -52px;
            .ins_item{
                position: relative;
                display: flex;
                flex-direction: column;
                border-radius: 15px;
                height: fit-content;
                width: 8em;
                border: 1px solid rgba(234, 234, 234, 1);
                box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.1), 5px 0 5px -5px rgba(0, 0, 0, 0.1), 0 5px 10px rgba(0, 0, 0, 0.1);
                margin-top: 13px;
                z-index: 99;
                &:hover{
                    border: 1px solid rgba(2, 0, 107, 1);
                    cursor: pointer;
                }
                .ins_white_div{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 13px;
                    padding: 14px 0px;
                    background-color: white;
                    border-radius: 15px;
                    min-height: 114px;
                    .popularImg{
                        height: 16px;
                        position: absolute;
                        top: -6px;
                    }
                    .ins_prop_img{
                        width: 42px;
                        height: 42px;
                    }
                    .ins_prop{
                        text-align: center;
                        font-size: 14px;
                        color: rgba(2, 0, 107, 1);
                        line-height: 14px;
                        font-weight: 500;
                    }
                    .long{
                        display: inline;
                    }
                    .short{
                        display: none;
                    }
                }
                .ins_price_div{
                    background: rgba(2, 0, 107, 1);
                    border-radius: 0 0 15px 15px;
                    display: flex;
                    justify-content: center;
                    padding: 2px;
                    .ins_rupees{
                        color: rgba(255, 255, 255, 1);
                        font-size: 17px;
                        font-weight: 600;
                    }
                }
            }
        }
        .whyChooseDiv{
            display: flex;
            background-color: rgba(244, 244, 244, 1);
            margin-top: 28px;
            border-radius: 10px;
            .girlImg{
                margin-top: -8em;
                img{
                    height: 100%;
                }
            }
            .whyChooseTextDiv{
                display: flex;
                flex-direction: column;
                padding: 20px 60px 20px 0;
                gap: 8%;
                .chooseTextHeading{
                    color: rgba(2, 0, 107, 1);
                    font-size: 29px;
                    font-weight: 600;
                }
                .chooseTextDesc{
                    color: rgba(52, 52, 52, 1);
                    font-size: 16px;
                }
                .longShort{
                    display: inline;
                }
                .shortChoose{
                    display: none;
                }
                .chooseTextPointsDiv{
                    .chooseTextPointsInnerDiv{
                        width: 70%;
                        display: flex;
                        gap: 20px;
                        align-items: center;
                        .choseTextPoint{
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            color: rgba(255, 0, 12, 1);
                            font-weight: 500;
                        }
                        .vertical{
                            height: 2em;
                        }
                    }
                }
            }
        }
        .topSelling{
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            .topSellingTextDiv{
                display: flex;
                flex-direction: column;
                width: 55%;
                gap: 10px;
                justify-content: center;
                .topSellingHeading{
                    font-size: 30px;
                    color: rgba(2, 0, 107, 1);
                    font-weight: 600;
                }
                .topSellingDesc{

                }
                .longTop{
                    display: inline;
                }
                .shortTop{
                    display: none;
                }
                .longHeading{
                    display: inline;
                }
                .shortHeading{
                    display: none;
                }
            }
            .topSellingPropsDiv{
                display: flex;
                flex-direction: column;
                gap: 14px;
                width: 33%;
                .topSellingPropsInnerDiv1, .topSellingPropsInnerDiv2{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 3%;
                    justify-content: flex-end;
                    .topSellingProp{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 5px;
                        border: 1px solid rgba(234, 234, 234, 1);
                        background: rgba(250, 250, 250, 1);
                        border-radius: 10px;
                        padding: 23px 2px;
                        width: 31%;
                        cursor: pointer;
                        &:hover{
                            border: 1px solid rgb(2, 0, 107);
                        }
                        .top_prop_img{
                            height: 37px;
                        }
                        .top_prop_text{
                            font-size: 12px;
                            text-align: center;
                            color: rgba(2, 0, 107, 1);
                            font-weight: 500;
                        }
                        .short{
                            display: none;
                        }
                        .long{
                            display: inline;
                        }
                    }
                }
                
            }
        }
        .insur_partner{
            background-color: rgb(244, 244, 244);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 65px 0px;
            gap: 40px;
            border-radius: 10px;
            margin-top: 30px;
            .ins_partner_heading{
                color: rgba(2, 0, 107, 1);
                font-size: 20px;
                font-size: 31px;
                font-weight: 600;
            }
            .ins_partner_Imgs_div{
                display: flex;
                width: 70%;
                justify-content: space-between;
                .ins_partner_img{
                    height: 68px;
                }
            }
        }
        .smiling_div{
            display: flex;
            background-color: rgba(244, 244, 244, 1);
            margin-top: 28px;
            border-radius: 10px;
            .smileImg{
                margin-top: -6em;
                width: 50%;
                display: flex;
                justify-content: center;
                img{
                    height: 25em;
                }
            }
            .smilingTextDiv{
                display: flex;
                flex-direction: column;
                padding: 30px 60px 20px 0;
                gap: 20px;
                .smilingTextHeading{
                    color: rgba(2, 0, 107, 1);
                    font-size: 30px;
                    font-weight: 600;
                }
                .smilingTextDesc{
                    font-size: 17px;
                }
                .longSmilingText{
                    display: inline;
                }
                .shortSmilingText{
                    display: none;
                }
                .BuyBtn{
                    width: fit-content;
                    background: rgba(2, 0, 107, 1);
                    color: white;
                    padding: 11px 34px;
                    border-radius: 22px;
                    margin-top: 30px;
                }   
            }
        }
        .blogs{
            margin-top: 57px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 29px;
            .blogs_heading{
                color:rgba(2, 0, 107, 1);
                font-size: 27px;
                font-weight: 600;
            }
            .blogs_outer_container{
                width: 100%;
                overflow-x: auto;
                display: flex;
                flex-direction: column;
                // align-items: center;
                .blog_div_all{
                    display: flex;
                    justify-content: space-between;
                    .blog_div{
                        display: flex;
                        flex-direction: column;
                        background-color: rgb(244, 244, 244);
                        padding: 39px;
                        border-radius: 20px;
                        width: 30%;
                        gap: 10px;
                        .blog_img{
                            
                        }
                        .details_div{
                            display: flex;
                            justify-content: flex-start;
                            gap: 22px;
                            align-items: center;
                            .date{
                                font-size: 10px;
                                color:rgba(0, 0, 102, 1);
                                font-weight: 500;
                            }
                            .dot{
                                width: 5px;
                                height: 5px;
                            }
                            .byWho{
                                color: rgba(0, 0, 102, 0.5);
                                font-size: 10px;
                            }
                        }
                        .blog_heading{
                            color: rgba(0, 0, 102, 1);
                            font-size: 18px;
                            font-weight: 600;
                        }
                        .blog_desc{
                            font-size: 14px;
                            color: rgba(0, 0, 102, 0.5);
                            font-weight: 400;
                            .readMoreBlog{
                                text-decoration: underline;
                                font-size: 16px;
                                color: rgba(0, 0, 102, 1);
                                font-weight: 500;
                                cursor: pointer;
                                
                            }
                        }
                        .mobile{
                            display: none;
                        }
                    }
                }
            }
            
            
        }
        .FAQ{
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            margin-bottom: 30px;
            // padding: 0 15px;
            .faq_header_div{
                display: flex;
                align-items: center;
                gap: 23px;
                .faq_haeding{
                    font-size: 20px;
                    color: rgba(2, 0, 107, 1);
                    font-weight: 600;
                }
            }
            .faq_content_div{
                display: flex;
                flex-direction: column;
                gap: 20px;
                height: 20em;
                overflow: auto;
                .faq_ques_ans_div{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    border-bottom: 1px solid rgb(220 218 218);
                    padding-bottom: 20px;
                    .faq_ques_div{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-right: 10px;
                        .faq_ques{
                            color: rgba(50, 50, 50, 1);
                            font-size: 18px;
                            font-weight: 500;
                        }
                        .down_vector_faq{
                            height: 10px;
                            cursor: pointer;
                            transition: transform 0.3s ease;
                        }
                        .up{
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
        .popup{
            width: 40%;
        }
        .blog_img{
            margin-bottom: 10px;
            border-radius: 19px;
        }
        .readMorePopupHeading{
            font-size: 24px;
            font-weight: 700;
            color: rgba(2, 0, 107, 1);
        }
        .readMorePopupDesc{
            color: rgba(156, 156, 156, 1);
            font-size: 17px;
            font-weight: 500;
        }
    }
}
.itemOptionsContent{
    display: flex;
    flex-direction: column;
    gap: 43px;
    .itemOptionTitle{
        display: flex;
        justify-content: center;
        font-size: 23px;
        font-weight: 600;
    }
    .itemOptions{
        display: flex;
        flex-wrap: wrap;
        gap: 3%;
        justify-content: center;
        .option{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            background: rgb(250, 250, 250);
            border: 1px solid rgb(216 214 214);
            border-radius: 10px;
            padding: 23px 10px;
            width: 150px;
            margin-top: 3%;
            span{
                color: rgb(2, 0, 107);
                font-size: 15px;
                font-weight: 300;
                text-align: center;
                font-weight: 500;
            }
        }
    }
}

@media (max-width: 1292px) {
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingPropsDiv {
        width: 40%;
    }
}
@media (max-width: 1230px) {
    .insurance_homepage .insurance_homepage_inner .insur_partner .ins_partner_Imgs_div {
        width: 80%;
    }
}

@media (max-width: 1213px) {
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div .long{
        display: none !important;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div .short{
        display: inline !important;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .girlImg img {
        width: 63em;
    }
}
@media (max-width: 1199px) {
    .insurance_homepage .insurance_homepage_inner .insurance_items{
        margin-top: -52px;
    }
    #default-carousel {
        margin-top: -4em !important;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .girlImg {
        margin-top: -7em;
    }
}
@media (max-width: 1161px) {
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item {
        width: 7em;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div {
        gap: 6px;
        padding: 11px 0px;
        min-height: 100px;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div .ins_prop {
        font-size: 12px;
        font-weight: 600;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div .ins_prop_img {
        width: 38px;
        height: 38px;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_price_div .ins_rupees {
        font-size: 14px;
        font-weight: 600;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .girlImg {
        margin-top: -7em;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextHeading {
        font-size: 27px;
    }
}
@media (max-width: 1117px) {
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv {
        padding: 13px 60px 30px 0;
        gap: 10px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextHeading {
        font-size: 23px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextDesc {
        font-size: 14px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextPointsDiv .chooseTextPointsInnerDiv .choseTextPoint {
        font-size: 11px;
    }

}
@media (max-width: 1091px) {
    .insurance_homepage .insurance_homepage_inner .insur_partner .ins_partner_Imgs_div {
        width: 94%;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg {
        width: 40%;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div {
        gap: 35px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg img {
        height: 23em;
    }
}
@media (max-width: 1027px) {
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingPropsDiv {
        width: 45%;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingTextDiv {
        width: 50%;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv {
        gap: 13px;
        padding: 30px 17px 20px 0;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv .BuyBtn{
        margin-top: unset;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv .longSmilingText{
        display: none !important;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv .shortSmilingText{
        display: inline !important;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg img {
        height: 21em;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div {
        margin-top: 17px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg {
        margin-top: -5em;
    }
}
@media (max-width: 988px) {
    .insurance_homepage .insurance_homepage_inner .insurance_items{
        margin-top: -52px;
    }
    #default-carousel {
        margin-top: -6em !important;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextHeading {
        font-size: 21px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextDesc {
        font-size: 12px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv {
        padding: 13px 37px 20px 0;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .girlImg img {
        width: 59em;
    }
    .slick-prev:before, .slick-next:before {
        color: rgb(255, 255, 255) !important; 
        font-size: 32px !important;
    }
    .slick-prev, .slick-next {
        width: 29px !important;
        height: 27px !important;
    }
    .insurance_homepage .insurance_homepage_inner .popup{
        width: 60%;
    }
}
@media (max-width: 916px) {
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingTextDiv .topSellingHeading {
        font-size: 28px;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingTextDiv .topSellingDesc{
        font-size: 14px;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingPropsDiv .topSellingPropsInnerDiv2 .topSellingProp .short{
        display: inline !important;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingPropsDiv .topSellingPropsInnerDiv2 .topSellingProp .long{
        display: none !important;
    }
    .insurance_homepage .insurance_homepage_inner .insur_partner .ins_partner_Imgs_div .ins_partner_img {
        height: 58px;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div {
        width: 31%;
    }
}
@media (max-width: 890px) {
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg img {
        height: 18em;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv .smilingTextHeading {
        font-size: 24px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv .smilingTextDesc {
        font-size: 15px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv .BuyBtn {
        padding: 7px 25px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv {
        padding: 16px 17px 16px 0;
    }
    .faq_ans_div{
        font-size: 14px;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div .details_div {
        gap: 10px;
    }
}
@media (max-width: 860px) {
    .insurance_homepage .insurance_homepage_inner .carousalDiv {
        margin-top: -28px;
    }
    .slick-slide img {
        // height: 13em;
        height: auto;
        width: 100%;
        object-fit: cover;
    }
}
@media (max-width: 771px) {
    .insurance_homepage .insurance_homepage_inner .insur_partner .ins_partner_Imgs_div .ins_partner_img {
        height: 49px;
    }
    .insurance_homepage .insurance_homepage_inner .insur_partner {
        padding: 36px 0px;
    }
    .insurance_homepage .insurance_homepage_inner .insur_partner .ins_partner_heading {
        font-size: 27px;
    }
}

@media (max-width: 765px) {
    .insurance_homepage .insurance_homepage_inner .insurance_items{
        margin-top: -88px;
    }
    #default-carousel {
        margin-top: -2em !important;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item {
        width: 6em;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div {
        gap: 6px;
        padding: 6px 0px 8px 0px;
        min-height: 83px;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div .ins_prop {
        font-size: 10px;
        font-weight: 600;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div .ins_prop_img {
        width: 28px;
        height: 28px;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_price_div .ins_rupees {
        font-size: 11px;
        font-weight: 600;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div .popularImg {
        height: 14px;
    }
    .insurance_homepage .insurance_homepage_inner .redLineDiv {
        height: 6px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv {
        margin-left: -11em;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .girlImg img {
        width: 85em;
        height: 16em;
        margin-left: -4em;
        margin-top: 2em;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextPointsDiv {
            display: none;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingTextDiv {
        width: 81%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingPropsDiv {
        width: 81%;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingTextDiv .topSellingDesc {
        text-align: center;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div .blog_heading {
        color: rgb(0, 0, 102);
        font-size: 15px;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div .blog_desc {
        font-size: 12px;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div .web{
        display: none;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div .mobile{
        display: block;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div .blog_desc .readMoreBlog {
        font-size: 12px;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div {
        gap: 2px;
        padding: 10px;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div .details_div {
        gap: 5px;
    }
    .insurance_homepage .insurance_homepage_inner .FAQ .faq_content_div .faq_ques_ans_div .faq_ques_div .down_vector_faq {
        height: 8px;
    }
    .insurance_homepage .insurance_homepage_inner .FAQ .faq_content_div .faq_ques_ans_div .faq_ques_div .faq_ques {
        font-size: 16px;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items {
        margin-top: -48px;
    }
}
@media (max-width: 684px) {
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div {
        width: 32%;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div .blog_desc .readMoreBlog {
        font-size: 12px;
        font-weight: 700;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div .blog_heading {
        color: rgb(0, 0, 102);
        font-size: 14px;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all .blog_div .blog_desc {
        font-size: 12px;
    }
    .insurance_homepage .insurance_homepage_inner .FAQ .faq_content_div .faq_ques_ans_div .faq_ques_div .faq_ques{
        font-size: 16px;
    }
    .insurance_homepage .insurance_homepage_inner .FAQ .faq_content_div .faq_ques_ans_div{
        padding-bottom: 6px;
    }
    .insurance_homepage .insurance_homepage_inner .FAQ .faq_content_div{
        gap: 17px;
    }
}
@media (max-width: 662px){
    .slick-slide img {
        // height: 13em;
        height: auto;
        width: 100%;
        object-fit: cover;
    }
    .insurance_homepage .insurance_homepage_inner .carousalDiv {
        margin-top: -30px;
    }
    .itemOptionsContent {
        gap: 22px;
    }
    .popup {
        padding: 20px 20px 39px 20px;
    }
    .itemOptionsContent .itemOptions .option {
        padding: 12px 10px;
        width: 125px;
    }
}
@media (max-width: 648px) {
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg {
        margin-top: -4em;
    }
}

@media (max-width: 648px) {
    .insurance_homepage .insurance_homepage_inner .insur_partner .ins_partner_Imgs_div .ins_partner_img {
        height: 68px;
    }
    .insurance_homepage .insurance_homepage_inner .insur_partner .ins_partner_Imgs_div{
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }
}
@media (max-width: 623px) {
    // .insurance_homepage .insurance_homepage_inner .insurance_items{
    //     margin-top: -62px;
    // }
    #default-carousel {
        margin-top: -2em !important;
    }
}
@media (max-width: 583px) {
    .longChoose{
        display: none !important;
    }
    .shortChoose{
        display: inline !important;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .girlImg img {
        width: 45em;
        margin-left: -3em;
        height: 14em;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv {
        padding: 13px 11px 20px 0;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv {
        margin-left: -9em;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg {
        width: 53%;
    }
    .insurance_homepage .insurance_homepage_inner .blogs .blogs_outer_container .blog_div_all{
        width: 150%;
        overflow-x: auto !important;
    }
    .insurance_homepage .insurance_homepage_inner {
        width: 98%;
    }
    .slick-prev {
        left: -5px !important;
    }
    .slick-next {
        right: -2px !important;
    }
    .slick-prev:before, .slick-next:before {
        font-size: 27px !important;
    }
    .slick-prev, .slick-next {
        width: 23px !important;
        height: 22px !important;
    }
    .insurance_homepage .insurance_homepage_inner .FAQ {
        padding: 0 15px;
    }
}
@media (max-width: 563px) {
    .insurance_homepage .insurance_homepage_inner .popup{
        width: 90%;
    }
}
@media (max-width: 544px) {
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingTextDiv .longTop{
        display: none !important;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingTextDiv .shortTop{
        display: inline !important;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingTextDiv {
        width: 100%;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingPropsDiv {
        width: 90%;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv .smilingTextHeading {
        font-size: 20px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv .smilingTextDesc {
        font-size: 13px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv {
        gap: 8px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg {
        width: 60%;
    }
    .faq_ans_div {
        font-size: 13px;
    }
    .slick-slide img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }
    .insurance_homepage .insurance_homepage_inner .carousalDiv {
        margin-top: -22px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg img {
        height: 15em;
    }
}
@media (max-width: 507px) {
    #default-carousel {
        margin-top: -2em !important;
    }
    .insurance_homepage .insurance_homepage_inner .redLineDiv {
        height: 4px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextHeading {
        font-size: 17px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextDesc {
        font-size: 13px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv {
        margin-left: -8em;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .girlImg img {
        margin-top: 2.5em;
    }
    .insurance_homepage .insurance_homepage_inner .insur_partner {
        padding: 25px 0px;
        gap: 22px;
    }
    .insurance_homepage .insurance_homepage_inner .insur_partner .ins_partner_Imgs_div .ins_partner_img {
        height: 68px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg img {
        height: 14em;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv .BuyBtn {
        padding: 4px 25px;
        font-size: 13px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg {
        width: 82%;
        margin-left: -5em;
        margin-top: -3em;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div {
        gap: 12px;
    }
}
@media (max-width: 450px) {
    // .insurance_homepage .insurance_homepage_inner .insurance_items{
    //     margin-top: -80px;
    // }
    // .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item {
    //     width: 6em;
    // }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div {
        .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div{
            gap: 4px;
        }
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextHeading {
        font-size: 16px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextDesc {
        font-size: 11px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .girlImg img {
        width: 34em;
        margin-left: -3em;
        margin-top: 3.8em;
        height: 11em;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv {
        margin-left: -6em;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv {
        margin-top: 21px;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingTextDiv .topSellingHeading {
        font-size: 24px;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingTextDiv .topSellingDesc {
        font-size: 13px;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingPropsDiv .topSellingPropsInnerDiv1 .topSellingProp, .insurance_homepage .insurance_homepage_inner .topSelling .topSellingPropsDiv .topSellingPropsInnerDiv2 .topSellingProp{
        padding: 13px 2px;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingPropsDiv .topSellingPropsInnerDiv1 .topSellingProp .top_prop_img, .insurance_homepage .insurance_homepage_inner .topSelling .topSellingPropsDiv .topSellingPropsInnerDiv2 .topSellingProp .top_prop_img {
        height: 28px;
    }
    .insurance_homepage .insurance_homepage_inner .FAQ .faq_content_div .faq_ques_ans_div .faq_ques_div .faq_ques {
        font-size: 15px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div {
        margin-top: 7px;
    }
}
@media (max-width: 423px) {
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv {
        padding: 7px 11px 0px 0;
        gap: 2px;
    }
    .insurance_homepage .insurance_homepage_inner .insur_partner .ins_partner_heading {
        font-size: 22px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg {
        margin-left: -2em;
    }
    .insurance_homepage .insurance_homepage_inner .carousalDiv {
        margin-top: -20px;
    }
    .slick-slide img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }
       .insurance_homepage .insurance_homepage_inner .insurance_items {
        margin-top: -33px;
    }
        .insurance_homepage .insurance_homepage_inner .carousalDiv {
        margin-top: -18px;
    }
}
@media (max-width: 415px) {
    .insurance_homepage .insurance_homepage_inner .insur_partner .ins_partner_Imgs_div .ins_partner_img {
        height: 63px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg img {
        height: 13em;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg {
        margin-top: -2em;
    }
}
@media (max-width: 401px) {
    #default-carousel {
        margin-top: -3em !important;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .girlImg img {
        margin-left: -2em;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingTextDiv .longHeading{
        display: none !important;
    }
    .insurance_homepage .insurance_homepage_inner .topSelling .topSellingTextDiv .shortHeading{
        display: inline !important;
    }
}
@media (max-width: 324px) {
    #default-carousel {
        margin-top: -5em !important;
    }
}
@media (max-width: 387px) {
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv {
        margin-top: 10px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .girlImg img {
        width: 34em;
        margin-left: -2.5em;
        margin-top: 5em;
        height: 9em;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv {
        margin-top: 10px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg {
        margin-left: -3em;
    }
    .insurance_homepage .insurance_homepage_inner .insur_partner .ins_partner_Imgs_div .ins_partner_img {
        height: 52px;
    }
}
@media (max-width: 375px) {
    .popup {
        padding: 20px 20px 25px 20px;
    }
    .popup-body {
        padding: 0 !important;
    }
    .itemOptionsContent {
        gap: 7px;
    }
    .insurance_homepage .insurance_homepage_inner .popup {
        width: 95%;
    }
    .popup {
        padding: 10px 10px 25px 10px;
    }
}
@media (max-width: 353px) {
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextDesc {
        font-size: 10px;
    }
    .insurance_homepage .insurance_homepage_inner .whyChooseDiv .whyChooseTextDiv .chooseTextHeading {
        font-size: 14px;
    }
    .insurance_homepage .insurance_homepage_inner .carousalDiv {
        margin-top: -15px;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items {
        margin-top: -29px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv .smilingTextHeading {
        font-size: 17px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv {
        gap: 3px;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smilingTextDiv {
        padding: 10px 17px 10px 0;
    }
    .insurance_homepage .insurance_homepage_inner .smiling_div .smileImg img {
        height: 11em;
    }
}
@media (max-width: 320px) {
    .insurance_homepage .insurance_homepage_inner .insur_partner .ins_partner_Imgs_div .ins_partner_img {
        height: 45px;
    }   
}
@media (max-width: 308px) {
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item {
        width: 5em;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div .ins_prop_img {
        width: 18px;
        height: 18px;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div .ins_prop {
        font-size: 9px;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_white_div {
        gap: 2px;
        padding: 6px 0px 3px 0px;
        min-height: 66px;
    }
    .insurance_homepage .insurance_homepage_inner .insurance_items .ins_item .ins_price_div .ins_rupees {
        font-size: 10px;
    }
}
