$color-white: #ffffff;
$color-blue-dark: #000066;
$color-red-light: #ee0025;
$color-purple-light: #e6e6f0;
$color-purple-light-two: rgba(0, 0, 102, 0.03);
$color-purple-light-three: rgba(0, 0, 0, 0.25);
$color-sky-blue: #6767da;
$color-red-dark: #ff0028;
$border-color-one: rgba(0, 0, 102, 0.2);
$para-color: rgba(0, 0, 102, 0.5);
$red-btn-hover: #c3001e;
$bg-color-grey: #808080;
$color-black: #000000;
$menuHover: #f0f0fc;
$menuHoverText: #a00019;
$light-blue: #f0f0fc;
$color-red: #a3021b;
$blue-light: #e2e2fe;
$blue-light-dark: #0700b2;
$grey-white-box-shadow: #ccc;
$blue-light-new: #D9D9FF;
$blue-one : #EAF0FF;
$blue-two : #0000B6;
$blue-three : rgba(217, 217, 255, 0.5);
$blue-four : #e6f7ff;
$orange : #FC5732;
$purple: #7B3DD9;
$green : #00B59A;
$yellow: #FE9600;
$blue : #2196F3;
$green2: rgba(0, 219, 48, 0.26);
$blue-five: rgb(21,47,107,0.9);
$stash-red: #A62241;

:root {
    --ThemeBlue: #000066;
    --BlueTwo: #000046;
    --LightBlueBackground: #f0f0fc;
    --White: #ffffff;
    --Black: #000000;
    --YellowTwo: #D3A500;
    --Creame: #E1E1E1;
    --Purple: #020924;
    --LightBlue: #E7E3FF;
}

:export {
    blueFive: $blue-five;
    pureWhite: $color-white;
    stashRed: $stash-red;
}