.elevateDisable_container{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 36px;
    height: 96vh;
    justify-content: space-between;
    overflow: auto;
    .message_div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin-top: 8em;
        .sad_img{
            height: 5em;
        }
        .sad_msg_heading{
            color: #000066;
            font-size: 20px;
            font-weight: 600;
        }
        .sad_msg{
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            text-align: center;
            color: rgba(0,0,0,0.8);
            .sad_msg_text{
                font-size: 16px;
            }
        }
    }
    .options_div{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .explore_other_text{
            color: #000000;
            font-size: 17px;
            font-weight: 600;
        }
        .option-card{
            display: flex;
            box-shadow: 0px 2px 8px 1px rgba(0, 0, 102, 0.08);
            border-radius: 13px;
            padding: 20px;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            .option_logo{
                height: 3em;
            }
            .option_content_div{
                display: flex;
                flex-direction: column;
                gap: 2px;
                .option_content_heading{
                    font-size: 16px;
                    font-weight: 600;
                    color: #000066;
                }
                .option_content_text{
                    font-size: 13px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.5);
                }
            }
            .right_open_arrow{
                height: 2em;
            }
        }
    }
    .backToHomeBtn{
        width: 100%;
        background: #AA013F;
        color: white;
        font-size: 17px;
        font-weight: 500;
        border-radius: 10px;
        padding: 17px;
    }
}
@media (max-width : 400px){
    .elevateDisable_container .message_div .sad_msg .sad_msg_text {
        font-size: 14px;
    }
}
@media (max-width : 365px){
    .elevateDisable_container .message_div .sad_img {
        height: 4em;
    }
    .elevateDisable_container .message_div .sad_msg_heading {
        font-size: 18px;
    }
    .elevateDisable_container .message_div .sad_msg .sad_msg_text {
        font-size: 13px;
    }
    .elevateDisable_container .options_div .explore_other_text {
        font-size: 14px;
    }
    .elevateDisable_container .options_div .option-card .option_logo {
        height: 2.5em;
    }
    .elevateDisable_container .options_div .option-card .right_open_arrow {
        height: 1.5em;
    }
    .elevateDisable_container .options_div .option-card .right_open_arrow {
        height: 1.5em;
    }
    .elevateDisable_container .options_div .option-card .option_content_div .option_content_text {
        font-size: 11px;
    }
    .elevateDisable_container .options_div .option-card {
        padding: 14px;
    }
    .elevateDisable_container {
        gap: 27px;
    }
}