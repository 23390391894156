@import "../../../assets/css/color.scss";
@import "../../../assets/css/MuiMediaQueries.scss";

.HomeBlock {
    clear: both;
    padding: 60px 0;
    .imageBlock {
        img {
            max-width: 420px;
        }
    }

    .button {
        color: #ffffff;
        font-size: 18px;
        font-weight: 700;
        padding: 24px 56px 24px 56px;
        text-decoration: none;
        border-radius: 45px;
        display: inline-block;
        background: $color-red-light;
        text-transform: inherit;

        &:hover {
            background: $color-red-light;
        }
    }

    .text-left {
        text-align: left;
    }

    .flex-row {
        align-items: center;
    }

    h2 {
        color: $color-blue-dark;
        font-size: 64px;
        font-weight: 400;
        @include xl-down {
            font-size: 60px;
        }

    }

    h2 {
        span {
            font-weight: 700;
        }
    }

    p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: $para-color;
        margin: 30px 0 40px;

        span {
            font-weight: 700;
            opacity: 1;
        }
        &.descSecond {
            color: #727171;
            font-size: 14px;
            font-weight: 400;
            line-height: 2;
        }
    }

    .text-right {
        text-align: right;
    }
}
.navigation ul li a:hover {
    color: $red-btn-hover;
}
.HomeBlock .button:hover {
    background-color: $red-btn-hover;
}
@media (min-width: 768px) {
    .HomeBlock .mobileImage {
        display: none;
    }
}
@media (max-width: 1199px) {
    .HomeBlock {
        h2 {
            font-size: 48px;
        }
        .imageBlock {
            img {
                max-width: 317px;
            }
        }
    }
}
@media (max-width: 1023px) {
    .HomeBlock {
        .imageBlock {
            img {
                max-width: 300px;
            }
        }
    }
    .HomeBlock {
        padding: 30px 0;
    }
    .HomeBlock h2 {
        font-size: 32px;
    }
    .HomeBlock p {
        font-size: 14px;
        line-height: 20px;
    }
    .HomeBlock .button {
        font-size: 16px;
        padding: 20px 45px;
    }
}

@media (max-width: 767px) {
    .HomeBlock {
        clear: both;
        padding: 25px 0;
    }
    .HomeBlock .flex-row {
        flex-direction: column-reverse !important;
    }
    .HomeBlock .imageBlock {
        display: none;
    }
    .HomeBlock .mobileImage {
        text-align: center;
        margin: 60px 0 40px;
        img {
            width: 210px;
        }
    }
    .HomeBlock {
        .blockText {
            text-align: center;
        }
        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $color-blue-dark;
            opacity: 0.5 !important;
            margin: 0 0 25px;
        }
    }
    .HomeBlock h2 {
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02em;
        margin-bottom: 40px;
        text-align: left;
        &.one-percent {
            margin-bottom: -20px;
        }
    }
    div.HomeBlock button {
        font-size: 11px !important;
        line-height: 17px;
        padding: 12px 19px !important;
    }
}
